import React from "react";
import SEO from "../../components/seo";

export default ({ data, params }) => {
  const [storeUrl, setStoreUrl] = React.useState("");
  console.log(`test ${params.inviteId}`);

  React.useEffect(() => {
    if (isAndroid()) {
      // window.open(`vybes://consumeinvite/${params.inviteId}`);
      // window.open(
      //   `intent://consumeinvite/${params.inviteId}/#Intent;scheme=vybes;package=io.vybes;end`
      // );
    } else if (isIOS()) {
      window.location.replace(`vybes://consumeinvite/${params.inviteId}`);
    }
  }, [params.inviteId]);

  return (
    <div>
      <SEO title="Invite" />

      <div
        style={{
          maxWidth: "960px",
          margin: "128px auto",
          width: "100%",
        }}
      >
        <p>Invite?</p>

        <a href={`vybes://consumeinvite/${params.inviteId}`}>Test</a>
      </div>
    </div>
  );
};

function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isAndroid() {
  return /(android)/i.test(navigator.userAgent);
}
